import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GET_CHARGER_STATION, GET_CONSUMPTION } from 'actions/chargingStation';
import Select from 'components/inputs/Select';
import { Row, Col } from 'react-bootstrap';
import * as _ from 'lodash';
import Chart from 'chart.js/auto';

const Consumption = () => {
  const dispatch = useDispatch();
  const { stationId } = useParams();
  const [selectedChargerId, setSelectedChargerId] = useState(`''`);
  const [chartType, setChartType] = useState('weekly');
  const chargerStationList = useSelector((state) => state?.chargerStation?.chargerList);
  //const limit = useSelector((state) => state.chargerStation.total);
  const consumptionData = useSelector((state) => state.consumption.consumptions);
  const chartRef = useRef(null);

  const consumption = useCallback(
    (value) => {
      const data = {
        ...value,
        stationID: [stationId],
        charger: value.charger === '' ? undefined : value.charger,
      };
      dispatch({ type: GET_CONSUMPTION, payload: data });
    },
    [consumptionData]
  );

  const getChargerStation = useCallback(
    (value) => {
      const data = {
        ...value,
        limit: 100,
        charging_station: [stationId],
      };
      dispatch({
        type: GET_CHARGER_STATION,
        payload: data,
      });
    },
    [chargerStationList]
  );

  useEffect(() => {
    consumption(stationId);
    getChargerStation({ id: stationId });
  }, [stationId]);

  const handleChargerSelect = (selectedOption) => {
    if (selectedOption === `''`) {
      setSelectedChargerId(selectedOption);
      consumption({ charger: '' });
    } else {
      setSelectedChargerId(selectedOption);
      consumption({ charger: selectedOption });
    }
  };

  const handleChartTypeChange = (type) => {
    setChartType(type);
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }
    const ctx = document.getElementById('lineChart').getContext('2d');
    const chartData = consumptionData[`${chartType}UnitConsumption`];
    if (chartData) {
      const sortedLabels = Object.keys(chartData).sort((a, b) => {
        const daysOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return daysOrder.indexOf(a) - daysOrder.indexOf(b);
      });
      const currentDate = new Date();
      const currentDayIndex = currentDate.toLocaleString('en', { weekday: 'long' });
      const currentIndex = sortedLabels.findIndex((label) => label === currentDayIndex);
      const reorderedLabels = [...sortedLabels.slice(currentIndex + 1), ...sortedLabels.slice(0, currentIndex + 1)];
      chartRef.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels: reorderedLabels,
          datasets: [
            {
              label: `Unit Consumption in kWh`,
              data: reorderedLabels.map((label) => chartData[label]),
              backgroundColor: '#FED035',
              borderColor: '#FED035',
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              ticks: {
                color: '#fff',
              },
              grid: {
                color: 'rgba(255,255,255,0.1)',
                borderColor: '#fff',
              },
            },
            y: {
              ticks: {
                color: '#fff',
              },
              grid: {
                color: 'rgba(255,255,255,0.1)',
                borderColor: '#fff',
              },
            },
          },
        },
      });
    }
  }, [consumptionData, chartType]);

  return (
    <>
      <div>
        <Row className="consumption_div">
          <Col lg={2} md={3} sm={6}>
            <Select
              label={'Select Charger'}
              options={[
                { label: 'All', value: `''` },
                ..._.map(chargerStationList, (item) => {
                  return { label: item.charger_id, value: item.id };
                }),
              ]}
              placeholder={'Select Charger'}
              name="chargerName"
              onChange={handleChargerSelect}
              value={selectedChargerId}
            />
          </Col>
          <Col lg={3} md={3} sm={6}>
            <div className="consumption_btn">
              <div onClick={() => handleChartTypeChange('weekly')} className={chartType === 'weekly' ? 'active' : ''}>
                Weekly
              </div>
              <div onClick={() => handleChartTypeChange('monthly')} className={chartType === 'monthly' ? 'active' : ''}>
                Monthly
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <canvas id="lineChart" width="400" height="150" style={{ marginTop: '1rem' }}></canvas>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Consumption;
