import { get } from 'utils/api';

export const getDashboardData = (data) => {
  return get(`/dashboard`, data);
};

export const getDashboardWidgetData = (data) => {
  return get(`/dashboard/widgets`, data);
};

export const getDashboardChartData = (data) => {
  return get(`/dashboard/charts`, data);
};

export const getDashboardPiechartData = (data) => {
  return get(`/dashboard/piechart`, data);
};

export const dashboard = {
  getDashboardData,
  getDashboardWidgetData,
  getDashboardChartData,
  getDashboardPiechartData,
};
