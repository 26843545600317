export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAIL = 'GET_DASHBOARD_DATA_FAIL';

export const GET_DASHBOARD_WIDGET_DATA = 'GET_DASHBOARD_WIDGET_DATA';
export const GET_DASHBOARD_WIDGET_DATA_SUCCESS = 'GET_DASHBOARD_WIDGET_DATA_SUCCESS';
export const GET_DASHBOARD_WIDGET_DATA_FAIL = 'GET_DASHBOARD_WIDGET_DATA_FAIL';

export const GET_DASHBOARD_CHART_DATA = 'GET_DASHBOARD_CHART_DATA';
export const GET_DASHBOARD_CHART_DATA_SUCCESS = 'GET_DASHBOARD_CHART_DATA_SUCCESS';
export const GET_DASHBOARD_CHART_DATA_FAIL = 'GET_DASHBOARD_CHART_DATA_FAIL';

export const GET_DASHBOARD_PIECHART_DATA = 'GET_DASHBOARD_PIECHART_DATA';
export const GET_DASHBOARD_PIECHART_DATA_SUCCESS = 'GET_DASHBOARD_PIECHART_DATA_SUCCESS';
export const GET_DASHBOARD_PIECHART_DATA_FAIL = 'GET_DASHBOARD_PIECHART_DATA_FAIL';