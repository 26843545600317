import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  CHANGE_STATION_STATUS,
  CHANGE_STATION_STATUS_FAIL,
  CHANGE_STATION_STATUS_SUCCESS,
  CREATE_CHARGING_STATION,
  CREATE_CHARGING_STATION_FAIL,
  CREATE_CHARGING_STATION_SUCCESS,
  DELETE_CHARGING_STATION,
  DELETE_CHARGING_STATION_FAIL,
  DELETE_CHARGING_STATION_SUCCESS,
  FETCH_CHARGING_STATION,
  FETCH_CHARGING_STATION_FAIL,
  FETCH_CHARGING_STATION_SUCCESS,
  GET_CHARGING_STATION,
  GET_CHARGING_STATION_FAIL,
  GET_CHARGING_STATION_SUCCESS,
  UPDATE_CHARGING_STATION,
  UPDATE_CHARGING_STATION_FAIL,
  UPDATE_CHARGING_STATION_SUCCESS,
  FETCH_REVIEWS,
  FETCH_REVIEWS_SUCCESS,
  FETCH_REVIEWS_FAIL,
  UPDATE_CONTACT_PERSON,
  UPDATE_CONTACT_PERSON_SUCCESS,
  UPDATE_CONTACT_PERSON_FAIL,
  GET_STATION_WIDGETS,
  GET_STATION_WIDGETS_SUCCESS,
  GET_STATION_WIDGETS_FAIL,
  GET_CONSUMPTION,
  GET_CONSUMPTION_SUCCESS,
  GET_CONSUMPTION_FAIL,
  GET_CHARGER_STATION,
  GET_CHARGER_STATION_SUCCESS,
  GET_CHARGER_STATION_FAIL,
  GET_STATION_INFO,
  GET_STATION_INFO_SUCCESS,
  GET_STATION_INFO_FAIL,
} from 'actions/chargingStation';

function* fetchChargingStation(action) {
  try {
    const response = yield call(API.getAllChargingStation, action.payload);
    yield put({ type: FETCH_CHARGING_STATION_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CHARGING_STATION_FAIL, payload: { error: e } });
  }
}

function* createChargingStation(action) {
  try {
    const response = yield call(API.addChargingStation, action.payload);
    yield put({ type: CREATE_CHARGING_STATION_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: CREATE_CHARGING_STATION_FAIL, payload: { error: e } });
  }
}

function* getChargingStation(action) {
  try {
    const response = yield call(API.getChargingStation, action.payload);
    yield put({ type: GET_CHARGING_STATION_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CHARGING_STATION_FAIL, payload: { error: e } });
  }
}

function* deleteChargingStation(action) {
  try {
    yield call(API.deleteChargingStation, action.payload);
    yield put({ type: DELETE_CHARGING_STATION_SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_CHARGING_STATION_FAIL, payload: { error: e } });
  }
}

function* updateChargingStation(action) {
  try {
    const response = yield call(API.updateChargingStation, action.payload);
    yield put({ type: UPDATE_CHARGING_STATION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CHARGING_STATION_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateContactPerson(action) {
  try {
    const response = yield call(API.updateContactPerson, action.payload);
    yield put({ type: UPDATE_CONTACT_PERSON_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CONTACT_PERSON_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* changeStationStatus(action) {
  try {
    yield call(API.updateChargingStation, action.payload);
    yield put({ type: CHANGE_STATION_STATUS_SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: CHANGE_STATION_STATUS_FAIL, payload: { error: e } });
  }
}

function* fetchStationReview(action) {
  try {
    const response = yield call(API.getStationReview, action.payload);
    yield put({ type: FETCH_REVIEWS_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_REVIEWS_FAIL, payload: { error: e } });
  }
}

function* getwidgets(action) {
  try {
    const response = yield call(API.getwidgets, action.payload);
    yield put({ type: GET_STATION_WIDGETS_SUCCESS, payload: response });
  } catch (e) {
    yield put({ type: GET_STATION_WIDGETS_FAIL, payload: { error: e } });
  }
}

function* consumption(action) {
  try {
    const response = yield call(API.consumption, action.payload);
    yield put({ type: GET_CONSUMPTION_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CONSUMPTION_FAIL, payload: { error: e } });
  }
}
function* getChargerStation(action) {
  try {
    const response = yield call(API.getChargerStation, action.payload);
    yield put({ type: GET_CHARGER_STATION_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CHARGER_STATION_FAIL, payload: { error: e } });
  }
}

function* getStationInfo(action) {
  try {
    const response = yield call(API.getStationInfo, action.payload);
    yield put({ type: GET_STATION_INFO_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_STATION_INFO_FAIL, payload: { error: e } });
  }
}

function* chargingStation() {
  yield takeLatest(FETCH_CHARGING_STATION, fetchChargingStation);
  yield takeLatest(CREATE_CHARGING_STATION, createChargingStation);
  yield takeLatest(GET_CHARGING_STATION, getChargingStation);
  yield takeLatest(DELETE_CHARGING_STATION, deleteChargingStation);
  yield takeLatest(UPDATE_CHARGING_STATION, updateChargingStation);
  yield takeLatest(CHANGE_STATION_STATUS, changeStationStatus);
  yield takeLatest(FETCH_REVIEWS, fetchStationReview);
  yield takeLatest(UPDATE_CONTACT_PERSON, updateContactPerson);
  yield takeLatest(GET_STATION_WIDGETS, getwidgets);
  yield takeLatest(GET_CONSUMPTION, consumption);
  yield takeLatest(GET_CHARGER_STATION, getChargerStation);
  yield takeLatest(GET_STATION_INFO, getStationInfo);
}

export default chargingStation;
