import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
// import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'components/inputs/Select';
import { CREATE_OEM_ERROR_CODE, UPDATE_OEM_ERROR_CODE } from 'actions/oemErrorCode';
import { FETCH_OEM_VENDOR } from 'actions/oemVendor';
import { FETCH_OCPP_ERROR } from 'actions/ocppErrorCode';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
import { FETCH_CHARGER_STATE } from 'actions/chargerState';
import { FETCH_OEM } from 'actions/oem';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { CREATE_BULK_ERROR_CODE } from 'actions/oemErrorCode';
// import fileDownload from 'js-file-download';
// import { BsUpload } from 'react-icons/bs';

const OemBulkErrorCodeForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const [bulkUploadStatus, setBulkUploadStatus] = useState(null);
  const dispatch = useDispatch();
  const oemErrorCodeDetail = useSelector((state) => state.oemErrorCode.oemErrorCodeDetail);
  const allOemVendor = useSelector((state) => state.oemVendor.oemVendors);
  const allChargerState = useSelector((state) => state.chargerState.chargerStates);
  // const isLoading = useSelector((state) => state.oemErrorCode.isLoading);
  const oemVendorPage = useSelector((state) => state.oemVendor.page);
  const oemVendorTotalPages = useSelector((state) => state.oemVendor.totalPages);
  const allOems = useSelector((state) => state.oem.oems);
  const oemTotalPages = useSelector((state) => state.oem.totalPages);
  const oemPage = useSelector((state) => state.oem.page);

  const addOemErrorCode = useCallback((data) => {
    dispatch({
      type: CREATE_OEM_ERROR_CODE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateOemErrorCode = useCallback((data) => {
    dispatch({
      type: UPDATE_OEM_ERROR_CODE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const getChargeSpotByOem = useCallback((id) => {
    dispatch({ type: FETCH_OEM, payload: id });
  }, []);

  const getAllOemVendor = useCallback((data) => {
    dispatch({ type: FETCH_OEM_VENDOR, payload: data });
  }, []);

  const getAllOcppErrors = useCallback((data) => {
    dispatch({ type: FETCH_OCPP_ERROR, payload: data });
  }, []);

  const getAllChargerState = useCallback((data) => {
    dispatch({ type: FETCH_CHARGER_STATE, payload: data });
  }, []);

  useEffect(() => {
    getAllOemVendor({ limit: 999 });
    getAllOcppErrors({ limit: 999 });
    getAllChargerState({ limit: 999 });
  }, []);

  useEffect(() => {
    if (isEdit && !_.isEmpty(oemErrorCodeDetail) && !_.isEmpty(allOemVendor)) {
      const selectedOem = _.find(allOemVendor, { name: _.get(oemErrorCodeDetail, 'vendor.name', '') });
      if (selectedOem && !_.isEmpty(selectedOem) && _.has(selectedOem, 'id')) {
        getChargeSpotByOem({ vendor: selectedOem.id });
      }
    }
  }, [isEdit, oemErrorCodeDetail, allOemVendor]);

  const OemErrorCodeSchema = Yup.object().shape({
    code: Yup.string().required('Vendor Error Code is required').strict(true).trim('Space is not allowed'),
    vendor: Yup.string().required('Vendor name is required').max(100, 'Text limit should be less than 100 characters'),
    state: Yup.string().required('Charger State name is required'),
    oem: Yup.string().required('ChargeSpot name is required'),
  });

  const initialValues = isEdit
    ? {
        ...oemErrorCodeDetail,
        errorCode: _.get(oemErrorCodeDetail, 'errorCode', ''),
        vendor: _.get(oemErrorCodeDetail, 'vendor.id', ''),
        state: _.get(oemErrorCodeDetail, 'state.id', ''),
      }
    : {
        code: '',
        vendor: '',
        state: '',
        errorCode: '',
        oem: '',
      };

  const processExcel = (values, data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);

    // Retrieve vendor name and charger spot name from form values
    const selectedVendor = _.find(allOemVendor, { id: values.vendor });
    const selectedChargerSpot = _.find(allOems, { id: values.oem });

    // Check if selectedVendor and selectedChargerSpot are not undefined
    if (selectedVendor && selectedChargerSpot) {
      const data = excelRows.reduce((acc, row, index) => {
        const rowNumber = index + 1; // For error messages to indicate the row

        // Check for missing values in required columns
        if (!row.vendor_error_code) {
          toast.error(`Missing vendor_error_code in row ${rowNumber}`);
          onClose();
          return acc; // Skip this row
        }
        if (!row.error_code) {
          toast.error(`Missing error_code in row ${rowNumber}`);
          onClose();
          return acc; // Skip this row
        }
        if (!row.cpo_code) {
          toast.error(`Missing cpo_code in row ${rowNumber}`);
          onClose();
          return acc; // Skip this row
        }
        if (!row.custom_error_code) {
          toast.error(`Missing custom_error_code in row ${rowNumber}`);
          onClose();
          return acc; // Skip this row
        }

        // Find matching charger state by cpo_code and custom_error_code
        const chargerState = allChargerState.find((object) => object.cpo_code === row.cpo_code && object.name === row.custom_error_code);

        // If custom_error_code does not match any object.name
        if (!chargerState) {
          toast.error(`No matching error code found in the system for custom_error_code: ${row.custom_error_code} in row ${rowNumber}`);
          onClose();
          return acc; // Skip this row
        }

        // Check if the row is already present in oemErrorCodeDetail to avoid duplicates
        const isDuplicate = _.find(oemErrorCodeDetail, {
          code: row.vendor_error_code.toString(),
          vendor: selectedVendor.id,
          oem: selectedChargerSpot.id,
        });

        if (isDuplicate) {
          // Show error toast for duplicate rows
          toast.error(`Duplicate entry found for vendor_error_code: ${row.vendor_error_code} in row ${rowNumber}`);
          onClose();
        } else {
          // Only add non-duplicate rows with valid chargerState
          acc.push({
            code: row.vendor_error_code.toString(),
            errorCode: row.error_code,
            state: chargerState.id, // Valid chargerState ID
            vendor: values.vendor,
            oem: values.oem,
          });
        }

        return acc;
      }, []);

      // If there are valid (non-duplicate) rows, proceed with bulk upload
      if (data.length > 0) {
        addBulkOem(data);
      } else {
        toast.error('No valid entries to upload');
      }
    } else {
      // Handle case where selected vendor or charger spot is not found
      toast.error('Selected vendor or charger spot not found');
      onClose();
    }
  };

  // Call processExcel with values and data
  const upload = (event, values) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      processExcel(values, reader.result);
    };
    reader.readAsBinaryString(file);
  };

  const addBulkOem = useCallback(
    (results, data) => {
      dispatch({
        type: CREATE_BULK_ERROR_CODE,
        payload: {
          results,
          data: data,
        },
        cb: (res) => {
          if (_.get(res, 'status') === CREATE_STATUS_CODE) {
            toast.success('Error Codes Added Successfully');
            // fileDownload(res.data, `${'Error Codes'}.xlsx`);
            setBulkUploadStatus(CREATE_STATUS_CODE);
          } else {
            toast.error('Unable to Upload Error Codes, Please Correct Data');
          }
        },
      });
    },
    [bulkUploadStatus]
  );

  useEffect(() => {
    if (bulkUploadStatus === CREATE_STATUS_CODE) {
      // Refresh page logic here
      window.location.reload(); // This will refresh the page
    }
  }, [bulkUploadStatus]);

  return (
    <React.Fragment>
      <div className="oemErrorCode-form-page__main">
        <Card>
          <div className="oemErrorCode-form-header__block">
            <div className="oemErrorCode-header-name">
              <span>{'Bulk Error Code Upload'}</span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="oemErrorCode-form-body__block">
            <div className="oemErrorCode-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={OemErrorCodeSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateOemErrorCode(values);
                  } else {
                    addOemErrorCode(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <Select
                          isRequired
                          label={'Vendor Name'}
                          options={_.map(allOemVendor, (item) => {
                            return { label: item.name, value: item.id };
                          })}
                          placeholder={'Select Vendor'}
                          name="vendor"
                          onMenuScrollDown={true}
                          getDataOnScrollDown={getAllOemVendor}
                          page={oemVendorPage}
                          totalPage={oemVendorTotalPages}
                          value={_.get(values, 'vendor', '')}
                          onChange={(val) => {
                            setFieldValue(`vendor`, val);
                            getChargeSpotByOem({ vendor: val });
                            setFieldValue(`oem`, '');
                          }}
                          error={errors.vendor && touched.vendor ? errors.vendor : null}
                        />
                      </Col>

                      <Col md={12}>
                        <Select
                          isRequired
                          label={'Chargespot Model'}
                          options={_.map(allOems, (item) => {
                            return { label: item.name, value: item.id };
                          })}
                          placeholder={'Select Model Name'}
                          name="oem"
                          onMenuScrollDown={true}
                          getDataOnScrollDown={(data) => getChargeSpotByOem({ ...data, vendor: values.vendor })}
                          page={oemPage}
                          totalPage={oemTotalPages}
                          value={_.get(values, 'oem', '')}
                          onChange={(val) => {
                            setFieldValue(`oem`, val);
                          }}
                          error={errors.oem && touched.oem ? errors.oem : null}
                        />
                      </Col>

                      <Col md={12}>
                        <div className="upload-file-block">
                          <input onChange={(event) => upload(event, values)} type="file" id="file" className="upload-file--input" />
                          <label className="upload-file-inner" htmlFor="file">
                            <div className="import-btn__block">
                              <div className="import_btn">
                                {/* <span className="rfid-upload-file-box-icon">
                                                                    <BsUpload />
                                                                </span> */}
                                {/* <span className="rfid-upload-file-box-text">{t('rfidEditList.importData')}</span> */}
                              </div>
                            </div>
                          </label>
                        </div>
                      </Col>
                    </Row>
                    {/* <div className="form-btn__block">
                                            <Button className="form_btn cancel____btn" onClick={onClose}>
                                                {t('button.cancel')}
                                            </Button>
                                            <Button type="submit" disabled={isSubmitting || isLoading} className="form_btn">
                                                {'Upload'}
                                            </Button>
                                        </div> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
OemBulkErrorCodeForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default OemBulkErrorCodeForm;
