import React, { useEffect, useCallback, useState } from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import * as _ from 'lodash';
import { CHARGER_STATUS, FETCH_CHARGER_STATUS_WIDGETS /*, CHARGER_STATUS_DOWNLOAD*/ } from 'actions/chargerStatus';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { startsWith } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { BiSort } from 'react-icons/bi';
//import { IoSearchOutline } from 'react-icons/io5';
import ReactPagination from 'components/general/ReactPagination';
// import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import Select from 'components/inputs/Select';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import OffCanvas from 'components/inputs/OffCanvas';
import { UPDATE_STATUS_CODE, CPO, SUPPORT } from 'components/common/constant';
import { RiEqualizerFill } from 'react-icons/ri';
import { GiLogicGateAnd } from 'react-icons/gi';
import { RiChargingPile2Line } from 'react-icons/ri';
import { FiZapOff } from 'react-icons/fi';
import { BsPlug } from 'react-icons/bs';
// import { GoPlug, GoStop } from 'react-icons/go';
// import fileDownload from 'js-file-download';
// import { BsDownload } from 'react-icons/bs';
import { FETCH_OEM_VENDOR } from 'actions/oemVendor';
import { FETCH_CHARGE_SPEED } from 'actions/chargeSpeed';
// import { FETCH_CITY, FETCH_COUNTRY, FETCH_STATE } from 'actions/address';
import { RxCross2 } from 'react-icons/rx';
import { TiTick } from 'react-icons/ti';
import { FETCH_ZONES } from 'actions/zoneManagement';
import { TbPlugOff } from "react-icons/tb";

const ChargerStatus = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  //const [showSearchBar, setShowSearchBar] = useState(false);
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [allChargingStationData, setAllChargingStationData] = useState([]);
  const [chargerFilter, setChargerFilter] = useState('');

  const chargerStatusList = useSelector((state) => state.chargerStatus.chargerStatus);
  const isLoader = useSelector((state) => state.chargerStatus.isLoading);
  // const allStation = useSelector((state) => state.chargingStation.chargingStations);
  const page = useSelector((state) => state.chargerStatus.page);
  const totalData = useSelector((state) => state.chargerStatus.total);
  const limit = useSelector((state) => state.chargerStatus.limit);
  const totalPages = useSelector((state) => state.chargerStatus.totalPages);
  const totalcharger = useSelector((state) => state.chargerStatus.totalChargers);
  const availableData = useSelector((state) => state.chargerStatus.available);
  const unavailableData = useSelector((state) => state.chargerStatus.unavailable);
  const inuseData = useSelector((state) => state.chargerStatus.inuse);
  const powerlossCount = useSelector((state) => state.chargerStatus.PowerLossCount);
  // const emergencyStopCount = useSelector((state) => state.chargerStatus.emergencyStopCount);
  // const underVoltageCount = useSelector((state) => state.chargerStatus.underVoltage);
  // const overVoltageCount = useSelector((state) => state.chargerStatus.overVoltage);
  const faultedCount = useSelector((state) => state.chargerStatus.faulted);
  const enabledCount = useSelector((state) => state.chargerStatus.enabled);
  const disabledCount = useSelector((state) => state.chargerStatus.disabled);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const currentUserRole = _.get(profileData, 'role', '');

  const chargerStatus = useCallback(
    (value) => {
      if (searchText) {
        const chargerStatusData = {
          ...value,
          search: searchText,
        };
        dispatch({
          type: CHARGER_STATUS,
          payload: chargerStatusData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
        };
        dispatch({
          type: CHARGER_STATUS,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [searchText]
  );

  const getWidgets = useCallback(
    (data) => {
      const chargerStatusData = {
        ...data,
        charging_station: userProfileStations,
      };
      dispatch({
        type: FETCH_CHARGER_STATUS_WIDGETS,
        payload: chargerStatusData,
      });
    },
    [userProfileStations] // Add currentTab as a dependency
  );

  useEffect(() => {
    getWidgets();
  }, [getWidgets]);

  const updateChargerStatus = (availability, isEnabled) => {
    const chargerStatusData = {
      charger_availibity: availability,
      charging_station: userProfileStations,
      is_enabled: isEnabled,
    };
    setActiveIndex(null);
    setChargerFilter(chargerStatusData);
    dispatch({
      type: CHARGER_STATUS,
      payload: chargerStatusData,
    });
  };

  /* const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        //charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All') {
        const filterData = {
          ...advanceFilterData, //charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : ''
        };
        const data = {
          ...filterData,
        };
        chargerStatus(data);
      } else {
        const data = {
          ...advanceFilterData,
        };
        chargerStatus(data);
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const filterDateData = {
        //charging_station: userProfileStations,
      };
      chargerStatus(filterDateData);
    } else {
      const filterDateData = {};
      chargerStatus(filterDateData);
    }
  }, [sortByItem, advanceFilterData]); */

  /* const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  }; */

  // const getAllStation = useCallback((data = {}) => {
  //   const stationData = {
  //     ...data,
  //     request_all: true,
  //   };
  //   dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  // }, []);

  useEffect(() => {
    if (chargerFilter) {
      if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        chargerStatus(chargerFilter);
      } else {
        chargerStatus(chargerFilter);
      }
    } else {
      if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const sortedOrder = 'desc';
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}status`,
          charging_station: userProfileStations,
        };
        chargerStatus(data);
      } else {
        const sortedOrder = 'desc';
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}status`,
        };
        chargerStatus(data);
      }
    }
  }, [searchText]);

  useEffect(() => {
    if (currentUserRole === CPO || currentUserRole === SUPPORT) {
      const filteredStations = chargerStatusList.filter((v) => userProfileStations.find((station) => station === v.id));
      setAllChargingStationData(filteredStations);
    } else {
      setAllChargingStationData(chargerStatusList);
    }
  }, [chargerStatusList]);

  useEffect(() => {
    if (currentUserRole === CPO || currentUserRole === SUPPORT) {
      const filteredStations = chargerStatusList.filter((v) => userProfileStations.find((station) => station === v.id));
      setAllChargingStationData(
        _.filter(
          filteredStations,
          (item) =>
            startsWith(item.name, searchText) ||
            startsWith(item.city, searchText) ||
            startsWith([..._.map(item.chargers, (data) => _.get(data, 'charger_id', ''))].toString(), searchText) ||
            startsWith([..._.map(item.chargers, (data) => _.get(data, 'oem.name', ''))].toString(), searchText)
        )
      );
    } else {
      setAllChargingStationData(
        _.filter(
          chargerStatusList,
          (item) =>
            startsWith(item.name, searchText) ||
            startsWith(item.city, searchText) ||
            startsWith([..._.map(item.chargers, (data) => _.get(data, 'charger_id', ''))].toString(), searchText) ||
            startsWith([..._.map(item.chargers, (data) => _.get(data, 'oem.name', ''))].toString(), searchText)
        )
      );
    }
  }, [searchText, chargerStatusList]);

  // useEffect(() => {
  //   if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
  //     const sortedOrder = 'desc';
  //     const data = {
  //       sortBy: `${sortedOrder === 'asc' ? '' : '-'}status`,
  //       charging_station: userProfileStations,
  //     };
  //     chargerStatus(data);
  //   } else {
  //     const sortedOrder = 'desc';
  //     const data = {
  //       sortBy: `${sortedOrder === 'asc' ? '' : '-'}status`,
  //     };
  //     chargerStatus(data);
  //   }
  // }, [searchText]);

  useEffect(() => {
    setAllChargingStationData(chargerStatusList);
  }, [chargerStatusList]);

  const getSortByItem = useCallback((name, order, page, filterDateData) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
        //charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      chargerStatus(data);
    } else if (filterDateData) {
      const data = {
        ...filterDateData,
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        //charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      chargerStatus(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        //charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      chargerStatus(data);
    }
  }, []);

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      setActiveIndex(null);
      const sortedOrder = sortByItem.order ? sortByItem.order : 'desc';
      const sortedItem = sortByItem.item ? sortByItem.item : 'status';
      let data = { page: page.selected + 1, sortBy: `${sortedOrder === 'asc' ? '' : '-'}${sortedItem}` };

      if (!_.isEmpty(advanceFilterData)) {
        data = { ...data, ...advanceFilterData };
      } else if (searchText) {
        // only use page number in this case
      } else if (chargerFilter) {
        data = { ...data, ...chargerFilter };
      }

      chargerStatus(data);
    },
    [sortByItem, advanceFilterData, userProfileStations, chargerFilter]
  );

  // const downloadChargerStatus = useCallback(() => {
  //   let basePayload = {
  //     excel: true,
  //   };
  //   if (searchText) {
  //     basePayload = { ...basePayload, search: searchText };
  //   }
  //   if (!_.isEmpty(advanceFilterData)) {
  //     basePayload = { ...basePayload, ...advanceFilterData };
  //   }
  //   if (chargerFilter) {
  //     basePayload = { ...basePayload, ...chargerFilter };
  //   }
  //   dispatch({
  //     type: CHARGER_STATUS_DOWNLOAD,
  //     payload: basePayload,
  //     cb: (res) => {
  //       if (res && _.get(res, 'status') === 200) {
  //         fileDownload(res.data, `${'Charger Status'}.xlsx`);
  //       }
  //     },
  //   });
  // }, [advanceFilterData, searchText, chargerFilter]);

  // const downloadFile = () => {
  //   downloadChargerStatus();
  // };

  //const search = <SearchBox preIcon={<BsSearch />} value={searchText} onChange={searchHandler} />;
  //const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);

  // const allCountries = useSelector((state) => state.country.countries);
  // const allState = useSelector((state) => state.state.states);
  // const allCity = useSelector((state) => state.city.city);
  // const allOemVendor = useSelector((state) => state.oemVendor.oemVendors);
  // const allChargeSpeed = useSelector((state) => state.chargeSpeed.chargeSpeed);
  // const countryPage = useSelector((state) => state.country.page);
  // const statePage = useSelector((state) => state.state.page);
  // const cityPage = useSelector((state) => state.city.page);
  // const countryTotalPage = useSelector((state) => state.country.totalPages);
  // const stateTotalPage = useSelector((state) => state.state.totalPages);
  // const cityTotalPage = useSelector((state) => state.city.totalPages);
  const allZones = useSelector((state) => state?.zoneManagement?.zones);
  const zonePage = useSelector((state) => state?.zoneManagement?.page);
  const zoneTotalPage = useSelector((state) => state?.zoneManagement?.totalPages);


  const initialValues = !_.isEmpty(advanceFilterData)
    ? { ...advanceFilterData }
    : { country: '', state: '', city: '', oem: '', charger_type: '', is_active: '', charger_availibity: '', access_type: '', zones: '' };

  // const getAllCountry = useCallback((data = {}) => {
  //   dispatch({ type: FETCH_COUNTRY, payload: data });
  // }, []);
  
  const getAllZones = useCallback((data = {}) => {
    dispatch({ type: FETCH_ZONES, payload: data });
  }, []);

  // const getStateByCountry = useCallback((data = {}) => {
  //   dispatch({ type: FETCH_STATE, payload: data });
  // }, []);

  // const getCityByState = useCallback((data = {}) => {
  //   dispatch({ type: FETCH_CITY, payload: data });
  // }, []);

  useEffect(() => {
    // getAllCountry();    
    getAllZones({ limit: 999 });
    dispatch({ type: FETCH_OEM_VENDOR, payload: { limit: 999 } });
    dispatch({ type: FETCH_CHARGE_SPEED });
  }, []);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Charger Status" />
        <div className="page-content-wrapper scrollable">
          <div className="status-blocks">
            <div className="status-block" onClick={() => updateChargerStatus('', '')} title="Total Number of Chargers">
              <div className="breating-area" style={{ background: '#ff8b001a' }}>
                {<RiChargingPile2Line color="#FF8B00" size={35} />}
              </div>
              <div className="status-info">
                <h1>{totalcharger || 0}</h1>
                <p>{'Total Chargers'}</p>
              </div>
            </div>
            <div className="status-block" onClick={() => updateChargerStatus('',true)} title="Number of Chargrers enabled">
              <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
                {<RiChargingPile2Line color="#4975F2" size={35} />}
              </div>
              <div className="status-info">
                <h1>{enabledCount || 0}</h1>
                <p>{'Enabled'}</p>
              </div>
            </div>
            <div className="status-block" onClick={() => updateChargerStatus('In Use', true)} title="Number of Chargrers Currently In Use">
              <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
                {<GiLogicGateAnd color="#4975F2" size={35} />}
              </div>
              <div className="status-info">
                <h1>{inuseData || 0}</h1>
                <p>{'In Use'}</p>
              </div>
            </div>
            <div className="status-block" onClick={() => updateChargerStatus('Available', true)} title="Number of Availabe Chargers">
              <div className="breating-area" style={{ background: '#14ac7f1a' }}>
                {<BsPlug color="#14AC7F" size={35} />}
              </div>
              <div className="status-info">
                <h1>{availableData || 0}</h1>
                <p>{'Available'}</p>
              </div>
            </div>
            <div className="status-block" onClick={() => updateChargerStatus('Unavailable', true)} title="Number of Unavailabe Chargers">
              <div className="breating-area" style={{ background: '#ff8b001a' }}>
                {<BsPlug color="#FF8B00" size={35} />}
              </div>
              <div className="status-info">
                <h1>{unavailableData || 0}</h1>
                <p>{'Unavailable'}</p>
              </div>
            </div>
            <div className="status-block" onClick={() => updateChargerStatus('Faulted', true)} title="Number of Chargrers faulted">
              <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
                {<FiZapOff color="#4975F2" size={35} />}
              </div>
              <div className="status-info">
                <h1>{faultedCount || 0}</h1>
                <p>{'Faulted'}</p>
              </div>
            </div>
            <div className="status-block" onClick={() => updateChargerStatus('PowerLoss', true)} title="Number of Chargrers with No Power Availability">
              <div className="breating-area" style={{ background: '#be210b21' }}>
                {<FiZapOff color="#BE210B" size={35} />}
              </div>
              <div className="status-info">
                <h1>{powerlossCount || 0}</h1>
                <p>{'PowerLoss'}</p>
              </div>
            </div>
            
            <div className="status-block" onClick={() => updateChargerStatus('', false)} title="Number of Chargrers disabled">
              <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
                {<TbPlugOff color="#4975F2" size={35} />}
              </div>
              <div className="status-info">
                <h1>{disabledCount || 0}</h1>
                <p>{'Disabled'}</p>
              </div>
            </div>
            {/* <div
              className="status-block"
              onClick={() => updateChargerStatus('Emergency stop pressed')}
              title="Number of Chargrers where Pressed Emergency Stop Button"
            >
              <div className="breating-area" style={{ background: '#c9a11b1a' }}>
                {<GoStop color="#C9A11B" size={35} />}
              </div>
              <div className="status-info">
                <h1>{emergencyStopCount || 0}</h1>
                <p>{'Emergency stop'}</p>
              </div>
            </div> */}

            {/* <div className="status-block" onClick={() => updateChargerStatus('voltageVariance')} title="Input Under Voltage & Input Over Voltage">
              <div className="breating-area" style={{ background: '#ff8b001a' }}>
                {<GoPlug color="#FF8B00" size={35} />}
              </div>
              <div className="status-info">
                <h1>{Math.floor(underVoltageCount + overVoltageCount) || 0}</h1>
                <p>{'Voltage Variance'}</p>
              </div>
            </div> */}
          </div>
          <div className="data-report--main">
            <div className="data-report--block">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col xl={12} lg={12} md={12} className="search_box_div">
                        <SearchBox
                          value={searchText}
                          preIcon={<BsSearch />}
                          postIcon={<RiEqualizerFill onClick={() => setShowAdvanceFilterPopup(true)} />}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </Col>
                      {/* <Col xl={1} md={1} className="search_btn_download">
                        <div className="excel-icon--block" onClick={downloadFile} title="Download">
                          <div className="report-excel-icon">
                            <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                          </div>
                        </div>
                      </Col> */}
                    </Row>
                    {/* <Row className="data-report__row">
                    <Col
                      xl={`${showSearchBar ? 2 : 1}`}
                      md={`${showSearchBar ? 12 : 2}`}
                      className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                    >
                      {showSearchBar ? search : searchIcon}
                    </Col>
                    <Col xl={1} md={2}>
                      <div className="report-search-box" onClick={dateFilter}>
                        <Button className="report-search-button">Search</Button>
                      </div>
                    </Col>
                    <Col xl={2} md={4}>
                      <div className="report-search-excel-block">
                        <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                          <Button className="report-search-button">Advance Filter</Button>
                        </div>
                      </div>
                    </Col>
                  </Row> */}
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>Station Name</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>OCPP ID</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Connectors</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Status</span>
                                <span className="ico" onClick={() => handleSorting('status', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Published</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Last Updated</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={11} className="border-0">
                                <div className="loader--block">
                                  <Spinner as="span" animation="border" size="sm" role="status" />
                                  <span className="ps-2">Loading...</span>
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(allChargingStationData) ? (
                            <tr>
                              <td colSpan={6} className="border-0">
                                <div className="empty-data-block">No Charger Status Found</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(allChargingStationData, (item0, index0) => {
                              if (_.get(item0, 'chargers')?.length > 0) {
                                return _.map(_.get(item0, 'chargers'), (item1, index1) => {
                                  const globalIndex = parseInt('' + index0 + index1);
                                  const serial_num = limit * (page - 1) + index1;
                                  const isPowerLoss = _.get(item1, 'charger_status', '') === 'PowerLoss';
                                  return (
                                    <React.Fragment key={`charger-status-${serial_num}`}>
                                      <tr>
                                        <td
                                          className="station-link"
                                          onClick={() => history.push(`/stations/${_.get(item0, 'id', '')}`, { backTo: 'chargerStatus' })}
                                        >
                                          {_.get(item0, 'name', '')}
                                        </td>
                                        <td
                                          className="charger-link"
                                          onClick={() => history.push(`/charger/${_.get(item1, 'id', '')}`, { backTo: 'chargerStatus' })}
                                        >
                                          {_.get(item1, 'charger_id', '')}
                                        </td>
                                        <td>
                                          <div className="connector-wrapper">
                                            {_.map(_.get(item1, 'plugs'), (item2, index2) => {
                                              const isCharging = _.get(item2, 'status', '') === 'Charging';
                                              const isPreparing = _.get(item2, 'status', '') === 'Preparing';
                                              const isFinishing = _.get(item2, 'status', '') === 'Finishing';
                                              const isAvailable = _.get(item2, 'status', '') === 'Available';
                                              const isUnavailable = _.get(item2, 'status', '') === 'Unavailable';
                                              const isFaulted = _.get(item2, 'status', '') === 'Faulted';

                                              let className = 'connector-circle';
                                              if (isCharging) {
                                                className += ' charging';
                                              } else if (isAvailable) {
                                                className += ' available';
                                              } else if (isUnavailable && isPowerLoss) {
                                                className += ' PowerLoss';
                                              } else if (isFaulted && isPowerLoss) {
                                                className += ' PowerLoss';
                                              } else if (isPreparing || isFinishing) {
                                                className += ' preparing';
                                              } else {
                                                className += ' unavailable';
                                              }

                                              return (
                                                <div key={index2} className={className}>
                                                  {_.get(item2, 'name', '')}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </td>
                                        <td>{_.get(item1, 'charger_status', '')}</td>
                                        <td>
                                          {_.get(item1, 'is_enabled') === true ? (
                                            <TiTick color={'#0f9b72'} size={30} />
                                          ) : (
                                            <RxCross2 color={'#BD210B'} size={30} />
                                          )}
                                        </td>
                                        <td>{moment.utc(_.get(item1, 'updatedAt', '')).local().format('DD/MM/YYYY H:mm:ss')} </td>
                                        <td className="dropdown-link">
                                          {activeIndex === globalIndex ? (
                                            <IoMdArrowDropup onClick={() => setActiveIndex(null)} />
                                          ) : (
                                            <IoMdArrowDropdown onClick={() => setActiveIndex(globalIndex)} />
                                          )}
                                        </td>
                                      </tr>
                                      {activeIndex === globalIndex && (
                                        <React.Fragment>
                                          {/* sub-table-head-row */}
                                          <tr className="sub-table-head-row" style={{ backgroundColor: '#141f29' }}>
                                            <th>
                                              <div className="sorting">
                                                <span>Chargepoint ID</span>
                                              </div>
                                            </th>
                                            <th>
                                              <div className="sorting">
                                                <span>Connectors</span>
                                              </div>
                                            </th>
                                            <th>
                                              <div className="sorting">
                                                <span>Status</span>
                                              </div>
                                            </th>
                                            <th>
                                              <div className="sorting">
                                                <span>Error Code</span>
                                              </div>
                                            </th>
                                            <th>
                                              <div className="sorting">
                                                <span>Error Message</span>
                                              </div>
                                            </th>
                                            <th></th>
                                            <th></th>
                                          </tr>
                                          {/* sub-table-body-row */}
                                          {_.map(_.get(item1, 'plugs'), (item3, index3) => {
                                            const isCharging = _.get(item3, 'status', '') === 'Charging';
                                            const isPreparing = _.get(item3, 'status', '') === 'Preparing';
                                            const isFinishing = _.get(item3, 'status', '') === 'Finishing';
                                            const isAvailable = _.get(item3, 'status', '') === 'Available';
                                            const isUnavailable = _.get(item3, 'status', '') === 'Unavailable';
                                            const isFaulted = _.get(item3, 'status', '') === 'Faulted';
                                            const chargerId = _.get(item1, 'charger_id', '');
                                            const chargerpointId = chargerId.slice(1) + _.get(item3, 'connector_id', '');

                                            let className = ' connector-circle';
                                            if (isCharging) {
                                              className += ' connector-charging';
                                            } else if (isAvailable) {
                                              className += ' connector-available';
                                            } else if (isUnavailable && isPowerLoss) {
                                              className += ' connector-PowerLoss';
                                            } else if (isFaulted && isPowerLoss) {
                                              className += ' connector-PowerLoss';
                                            } else if (isPreparing || isFinishing) {
                                              className += ' connector-preparing';
                                            } else {
                                              className += ' connector-unavailable';
                                            }

                                            let statusClassName = '';
                                            if (isCharging) {
                                              statusClassName = 'charging';
                                            } else if (isAvailable) {
                                              statusClassName = 'available';
                                            } else if (isUnavailable && isPowerLoss) {
                                              statusClassName = 'PowerLoss';
                                            } else if (isFaulted && isPowerLoss) {
                                              statusClassName = 'PowerLoss';
                                            } else if (isPreparing || isFinishing) {
                                              statusClassName = 'preparing';
                                            } else {
                                              statusClassName = 'unavailable';
                                            }

                                            return (
                                              <tr key={index3} className="sub-table-body-row">
                                                <td style={{ backgroundColor: '#141f29' }}>{chargerpointId}</td>
                                                <td style={{ backgroundColor: '#141f29' }}>
                                                  {/* <div className="connector-wrapper">
                                                    {isCharging || isPreparing || isFinishing ? (
                                                      <div className="connector-circle connector-charging">{_.get(item3, 'name', '')}</div>
                                                    ) : isAvailable ? (
                                                      <div className="connector-circle">{_.get(item3, 'name', '')}</div>
                                                    ) : (
                                                      <div className="connector-circle connector-unavailable">{_.get(item3, 'name', '')}</div>
                                                    )}
                                                  </div> */}
                                                  <div className="connector-wrapper">
                                                    <div className={className}>{_.get(item3, 'name', '')}</div>
                                                  </div>
                                                </td>
                                                {/* {isCharging || isPreparing || isFinishing ? (
                                                  <td className="charging" style={{ backgroundColor: '#141f29' }}>
                                                    {_.get(item3, 'status', '')}
                                                  </td>
                                                ) : isAvailable ? (
                                                  <td className="available" style={{ backgroundColor: '#141f29' }}>
                                                    {_.get(item3, 'status', '')}
                                                  </td>
                                                ) : (
                                                  <td className="unavailable" style={{ backgroundColor: '#141f29' }}>
                                                    {_.get(item3, 'status', '')}
                                                  </td>
                                                )} */}
                                                <td className={statusClassName} style={{ backgroundColor: '#141f29' }}>
                                                  {_.get(item3, 'status', '')}
                                                </td>
                                                <td style={{ backgroundColor: '#141f29' }}>{_.get(item3, 'errorCode', '')}</td>
                                                <td style={{ backgroundColor: '#141f29' }}>{_.get(item3, 'errorMessage', '')}</td>
                                                <td style={{ backgroundColor: '#141f29' }}></td>
                                                <td style={{ backgroundColor: '#141f29' }}></td>
                                              </tr>
                                            );
                                          })}
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  );
                                });
                              }
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(allChargingStationData) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                      showDataCount={false}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </LayoutContainer>
      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.country && delete values['country'];
              !values.state && delete values['state'];
              !values.city && delete values['city'];
              !values.oem && delete values['oem'];
              !values.charger_type && delete values['charger_type'];
              !values.access_type && delete values['access_type'];
              !values.is_active && delete values['is_active'];
              !values.charger_availibity && delete values['charger_availibity'];
              !values.zones && delete values['zones'];

              const filterData = {
                ...values,
                is_active: values.is_active === 'Not In Use' ? false : true,
              };
              chargerStatus(filterData);
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  {/* <Select
                    label={'Country'}
                    options={_.map(allCountries, (item) => {
                      return { label: item.name, value: item.name };
                    })}
                    className="filter-select-box"
                    placeholder={'Select Country'}
                    name="country"
                    value={values.country}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllCountry}
                    page={countryPage}
                    totalPage={countryTotalPage}
                    onChange={(val) => {
                      getStateByCountry({ country_name: val });
                      setFieldValue(`country`, val);
                      setFieldValue(`state`, '');
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={'State'}
                    options={_.map(allState, (item) => {
                      return { label: item.name, value: item.name };
                    })}
                    className="filter-select-box"
                    placeholder={'Select State'}
                    name="state"
                    value={values.state}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                    page={statePage}
                    totalPage={stateTotalPage}
                    onChange={(val) => {
                      getCityByState({ state_name: val });
                      const currentState = _.find(allState, { name: val });
                      setFieldValue(`state`, currentState.name);
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label={'City'}
                    options={
                      values.state &&
                      _.map(allCity, (item) => {
                        return { label: item.name, value: item.name };
                      })
                    }
                    placeholder={'Select City'}
                    name="city"
                    value={values.city}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={(data) => getCityByState({ ...data, state_name: values.state })}
                    page={cityPage}
                    totalPage={cityTotalPage}
                    onChange={(val) => {
                      const currentCity = _.find(allCity, { name: val });
                      setFieldValue('city', currentCity.name);
                    }}
                  />

                  <Select
                    label={'OEM Name'}
                    options={_.map(allOemVendor, (item) => {
                      return { label: item.name, value: item.id };
                    })}
                    placeholder={'Select OEM Name'}
                    name="oem"
                    value={_.get(values, 'oem', '')}
                    onChange={(val) => setFieldValue(`oem`, val)}
                  /> */}

                  <div className="canvas-selection__block">
                    {/* <Select
                      label={'Type Of Charger'}
                      options={_.map(allChargeSpeed, (item) => {
                        return { label: item.name, value: item.name };
                      })}
                      placeholder={'Select Charger Speed'}
                      name="charger_type"
                      value={values.charger_type}
                      onChange={(val) => setFieldValue('charger_type', val)}
                    />
                    <Select
                      label={'Charger Status'}
                      options={[
                        { label: 'Unavailable', value: 'Unavailable' },
                        { label: 'Available', value: 'Available' },
                        { label: 'In Use', value: 'In Use' },
                      ]}
                      placeholder={'Select Status'}
                      name="charger_availibity"
                      value={values.charger_availibity}
                      onChange={(val) => setFieldValue(`charger_availibity`, val)}
                    /> */}
                    <Select
                      label="Zones"
                      isMulti
                      options={_.map(allZones, (item) => {
                        return { label: item.name, value: item.id };
                      })}
                      className="filter-select-box"
                      placeholder="Select Zones"
                      name="zones"
                      value={values.zones}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={getAllZones}
                      page={zonePage}
                      totalPage={zoneTotalPage}
                      onChange={(val) => {
                        setFieldValue(`zones`, val);
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {'Apply Filter'}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      chargerStatus();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {'Clear'}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default ChargerStatus;
