import {
    GET_DASHBOARD_PIECHART_DATA,
    GET_DASHBOARD_PIECHART_DATA_SUCCESS,
    GET_DASHBOARD_PIECHART_DATA_FAIL,
  } from 'actions/dashboard';
  
  const initialState = {
    isLoading: false,
    dashboardPiechart: {},
  };
  
  const dashboardPieChartReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case GET_DASHBOARD_PIECHART_DATA: {
        return { ...state, isLoading: true };
      }
        
      case GET_DASHBOARD_PIECHART_DATA_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          dashboardPiechart: payload,
        };
      }
  
      case GET_DASHBOARD_PIECHART_DATA_FAIL: {
        return { ...state, isLoading: false };
      }
  
      default:
        return { ...state };
    }
  };
  export default dashboardPieChartReducer;
  