import {
    GET_DASHBOARD_CHART_DATA,
    GET_DASHBOARD_CHART_DATA_SUCCESS,
    GET_DASHBOARD_CHART_DATA_FAIL,
  } from 'actions/dashboard';
  
  const initialState = {
    isLoading: false,
    dashboardChart: {},
  };
  
  const dashboardChartReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case GET_DASHBOARD_CHART_DATA: {
        return { ...state, isLoading: true };
      }
        
      case GET_DASHBOARD_CHART_DATA_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          dashboardChart: payload,
        };
      }
  
      case GET_DASHBOARD_CHART_DATA_FAIL: {
        return { ...state, isLoading: false };
      }
  
      default:
        return { ...state };
    }
  };
  export default dashboardChartReducer;
  