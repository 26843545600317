import {
  CHANGE_STATION_STATUS,
  CHANGE_STATION_STATUS_FAIL,
  CHANGE_STATION_STATUS_SUCCESS,
  CREATE_CHARGING_STATION,
  CREATE_CHARGING_STATION_FAIL,
  CREATE_CHARGING_STATION_SUCCESS,
  DELETE_CHARGING_STATION,
  DELETE_CHARGING_STATION_FAIL,
  DELETE_CHARGING_STATION_SUCCESS,
  FETCH_CHARGING_STATION,
  FETCH_CHARGING_STATION_FAIL,
  FETCH_CHARGING_STATION_SUCCESS,
  GET_CHARGING_STATION,
  GET_CHARGING_STATION_FAIL,
  GET_CHARGING_STATION_SUCCESS,
  UPDATE_CHARGING_STATION,
  UPDATE_CHARGING_STATION_FAIL,
  UPDATE_CHARGING_STATION_SUCCESS,
  UPDATE_CONTACT_PERSON,
  UPDATE_CONTACT_PERSON_FAIL,
  UPDATE_CONTACT_PERSON_SUCCESS,
  GET_STATION_WIDGETS,
  GET_STATION_WIDGETS_SUCCESS,
  GET_STATION_WIDGETS_FAIL,
  GET_STATION_INFO,
  GET_STATION_INFO_SUCCESS,
  GET_STATION_INFO_FAIL,
} from 'actions/chargingStation';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  isLoading: false,
  chargingStations: [],
  chargingStationDetail: {},
};

const chargingStationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CHARGING_STATION:
    case CREATE_CHARGING_STATION:
    case GET_CHARGING_STATION:
    case DELETE_CHARGING_STATION:
    case UPDATE_CHARGING_STATION:
    case CHANGE_STATION_STATUS:
    case UPDATE_CONTACT_PERSON:
    case GET_STATION_WIDGETS:
    case GET_STATION_INFO: {
      return { ...state, isLoading: true };
    }

    case FETCH_CHARGING_STATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        chargingStations: payload,
      };
    }

    case GET_STATION_WIDGETS_SUCCESS: {
      return {
        ...state,
        // chargingStations: payload,
        available: payload.data?.availableCount,
        unavailable: payload.data?.unavailableCount,
        comingsoon: payload.data?.comingSoonCount,
        powerLoss: payload.data?.powerLossCount,
        inUse: payload.data?.inuseCount,
        underMaintenance: payload.data?.undermntnCount,
        totalCsCount: payload.data?.totalCount,
        disabledCount: payload.data?.disabledCount,
      };
    }

    case CREATE_CHARGING_STATION_SUCCESS: {
      toast.success('Station added successfully');
      window.location.href = '/chargerStatus';
      return {
        ...state,
        chargingStations: _.concat(state.chargingStations, payload),
        isLoading: false,
      };
    }

    case GET_CHARGING_STATION_SUCCESS: {
      return {
        ...state,
        chargingStationDetail: payload,
        isLoading: false,
      };
    }

    case GET_STATION_INFO_SUCCESS: {
      return {
        ...state,
        stationInfo: payload,
        isLoading: false,
      };
    }

    case DELETE_CHARGING_STATION_SUCCESS: {
      toast.success('Station deleted successfully');
      window.location.href = '/chargerStatus';
      return {
        ...state,
        chargingStations: state.chargingStations.filter((data) => data.id !== payload),
      };
    }

    case UPDATE_CONTACT_PERSON_SUCCESS: {
      toast.success('Contact Person updated Successfully');
      return {
        ...state,
        chargingStationDetail: { ...state.chargingStationDetail, contact_person: { ...state.chargingStationDetail.contact_person, ...payload } },
      };
    }

    case UPDATE_CHARGING_STATION_SUCCESS: {
      toast.success('Station updated successfully');
      setTimeout(() => {
        window.location.href = `/stations/${payload.id}`;
      }, 1000);
      return {
        ...state,
        chargingStationDetail: payload,
        chargingStations: state.chargingStations.map((data) => (data.id === payload.id ? payload : data)),
      };
    }

    case CHANGE_STATION_STATUS_SUCCESS: {
      toast.success(`Station ${payload.is_enabled === true ? 'Enabled' : 'Disabled'} successfully`);
      return {
        ...state,
        chargingStationDetail: { ...state.chargingStationDetail, is_enabled: payload.is_enabled },
      };
    }

    case FETCH_CHARGING_STATION_FAIL:
    case CREATE_CHARGING_STATION_FAIL:
    case GET_CHARGING_STATION_FAIL:
    case DELETE_CHARGING_STATION_FAIL:
    case UPDATE_CHARGING_STATION_FAIL:
    case CHANGE_STATION_STATUS_FAIL:
    case UPDATE_CONTACT_PERSON_FAIL:
    case GET_STATION_WIDGETS_FAIL:
    case GET_STATION_INFO_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default chargingStationReducer;
