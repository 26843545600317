import { CHARGE_TRANSACTION_REPORT, CHARGE_TRANSACTION_REPORT_SUCCESS, CHARGE_TRANSACTION_REPORT_FAIL } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  chargeTransactionReport: [],
  page: 1,
  limit: 10,
  totalPages: 1,
  total: 1,
};

const chargeTransactionReportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHARGE_TRANSACTION_REPORT: {
      return { ...state, isLoading: true };
    }
    case CHARGE_TRANSACTION_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        chargeTransactionReport: payload.results,
        page: payload.page,
        limit: payload.limit,
        totalPages: payload.totalPages,
        total: payload.totalResults,
      };
    }

    case CHARGE_TRANSACTION_REPORT_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default chargeTransactionReportReducer;
