import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_CHARGER_STATE, UPDATE_CHARGER_STATE } from 'actions/chargerState';
import { CREATE_STATUS_CODE, UPDATE_STATUS_CODE } from 'components/common/constant';
// import { useTranslation } from 'react-i18next';

const ChargerStateForm = (props) => {
  const { isEdit, onClose = _.noop(), onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  // const { t } = useTranslation();

  const chargerStateDetail = useSelector((state) => state.chargerState.chargerStateDetail);
  const isLoading = useSelector((state) => state.chargerState.isLoading);

  const addChargerState = useCallback((data) => {
    dispatch({
      type: CREATE_CHARGER_STATE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateChargerState = useCallback((data) => {
    dispatch({
      type: UPDATE_CHARGER_STATE,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const ChargerStateSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").strict(true).trim("Space is not allowed").max(100, "Text limit should be less than 100 characters"),

    cpo_code: Yup.number()
      .integer("Invalid CPO Code")
      .typeError("Invalid CPO Code")
      .required("Cpo Code is Required"),
  });

  const initialValues = isEdit
    ? { ...chargerStateDetail }
    : {
        name: '',
        cpo_code: '',
      };
  return (
    <React.Fragment>
      <div className="chargerState-form-page__main">
        <Card>
          <div className="chargerState-form-header__block">
            <div className="chargerState-header-name">
              <span>
                {isEdit ? 'Edit' : 'Add'} {"Charger State"}
              </span>
            </div>
            <div className="close___btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="chargerState-form-body__block">
            <div className="chargerState-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={ChargerStateSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (isEdit) {
                    updateChargerState(values);
                  } else {
                    addChargerState(values);
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label={"CPO Code"}
                          placeholder={"CPO Code"}
                          name="cpo_code"
                          value={_.get(values, 'cpo_code', '')}
                          onChange={handleChange}
                          error={errors.cpo_code && touched.cpo_code ? errors.cpo_code : null}
                        />
                        <TextInput
                          isRequired
                          label={"Name"}
                          placeholder={"Name"}
                          name="name"
                          value={_.get(values, 'name', '')}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                    </Row>
                    <div className="form-btn__block">
                      <Button className="form_btn cancel____btn" onClick={onClose}>
                        {"Cancel"}
                      </Button>
                      <Button type="submit" disabled={isSubmitting || isLoading} className="form_btn">
                        {isEdit ? 'Edit' : 'Add'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
ChargerStateForm.propTypes = {
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default ChargerStateForm;
