import {
  CHARGER_STATUS,
  CHARGER_STATUS_SUCCESS,
  CHARGER_STATUS_FAIL,
  CHARGER_STATUS_DOWNLOAD,
  CHARGER_STATUS_DOWNLOAD_SUCCESS,
  CHARGER_STATUS_DOWNLOAD_FAIL,
  FETCH_CHARGER_STATUS_WIDGETS,
  FETCH_CHARGER_STATUS_WIDGETS_SUCCESS,
  FETCH_CHARGER_STATUS_WIDGETS_FAIL,
} from '../actions/chargerStatus';

import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* fetchChargerStatus(action) {
  try {
    const response = yield call(API.getChargerStatus, action.payload);
    yield put({ type: CHARGER_STATUS_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHARGER_STATUS_FAIL, payload: { error: e } });
  }
}
function* fetchChargerWidgets(action) {
  try {
    const response = yield call(API.getChargerWidgets, action.payload);
    yield put({ type: FETCH_CHARGER_STATUS_WIDGETS_SUCCESS, payload: response });
    action.cs && action.cs(response);
  } catch (e) {
    yield put({ type: FETCH_CHARGER_STATUS_WIDGETS_FAIL, payload: { error: e } });
  }
}

function* downloadChargerStatus(action) {
  try {
    const response = yield call(API.downloadChargerStatus, action.payload);
    yield put({ type: CHARGER_STATUS_DOWNLOAD_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CHARGER_STATUS_DOWNLOAD_FAIL, payload: { error: e } });
  }
}

function* chargerStatus() {
  yield takeLatest(CHARGER_STATUS, fetchChargerStatus);
  yield takeLatest(CHARGER_STATUS_DOWNLOAD, downloadChargerStatus);
  yield takeLatest(FETCH_CHARGER_STATUS_WIDGETS, fetchChargerWidgets);
}
export default chargerStatus;
