import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  GET_DASHBOARD_WIDGET_DATA,
  GET_DASHBOARD_WIDGET_DATA_SUCCESS,
  GET_DASHBOARD_WIDGET_DATA_FAIL,
  GET_DASHBOARD_CHART_DATA,
  GET_DASHBOARD_CHART_DATA_SUCCESS,
  GET_DASHBOARD_CHART_DATA_FAIL,
  GET_DASHBOARD_PIECHART_DATA,
  GET_DASHBOARD_PIECHART_DATA_SUCCESS,
  GET_DASHBOARD_PIECHART_DATA_FAIL,
} from 'actions/dashboard';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* getDashboardData(action) {
  try {
    const response = yield call(API.getDashboardData, action.payload);
    yield put({ type: GET_DASHBOARD_DATA_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_DATA_FAIL, payload: { error: e } });
  }
}

function* getDashboardWidgetData(action) {
  try {
    const response = yield call(API.getDashboardWidgetData, action.payload);
    yield put({ type: GET_DASHBOARD_WIDGET_DATA_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_WIDGET_DATA_FAIL, payload: { error: e } });
  }
}

function* getDashboardChartData(action) {
  try {
    const response = yield call(API.getDashboardChartData, action.payload);
    yield put({ type: GET_DASHBOARD_CHART_DATA_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_CHART_DATA_FAIL, payload: { error: e } });
  }
}

function* getDashboardPiechartData(action) {
  try {
    const response = yield call(API.getDashboardPiechartData, action.payload);
    yield put({ type: GET_DASHBOARD_PIECHART_DATA_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_PIECHART_DATA_FAIL, payload: { error: e } });
  }
}

function* dashboard() {
  yield takeLatest(GET_DASHBOARD_DATA, getDashboardData);
  yield takeLatest(GET_DASHBOARD_WIDGET_DATA, getDashboardWidgetData);
  yield takeLatest(GET_DASHBOARD_CHART_DATA, getDashboardChartData);
  yield takeLatest(GET_DASHBOARD_PIECHART_DATA, getDashboardPiechartData);
}
export default dashboard;
