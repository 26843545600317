import React, { useCallback, useEffect, useState } from 'react';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { /*BsThreeDots,*/ BsDot, BsFillPlugFill } from 'react-icons/bs';
import DropDown from 'components/inputs/DropDown';
import { RiDeleteBin6Line } from 'react-icons/ri';
// import { AiOutlineCopy } from 'react-icons/ai';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import { Modal } from 'react-bootstrap';
import Switch from 'components/inputs/Switch';
import { GET_CHARGER, DELETE_CHARGER, CHANGE_CHARGER_STATUS } from 'actions/charger';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import * as _ from 'lodash';
import DeleteModal from 'components/general/DeleteModal';
import ChargerInfo from './ChargerInfo';
import LogInfo from './LogInfo';
import ServerOperationInfo from './ServerOperationInfo';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { FiEdit2 } from 'react-icons/fi';
import { FiMoreVertical } from 'react-icons/fi';
import { FiCopy } from 'react-icons/fi';
// import { MdClose, MdQrCodeScanner } from 'react-icons/md';
// import { GENERATE_QR_CODE } from 'actions/generateQrCode';
// import { saveAs } from 'file-saver';
import Footer from 'components/general/Footer';

const ChargerDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { chargerId } = useParams();
  // const [checked, setChecked] = useState(false);
  const [copied, setCopied] = useState(false);
  const [currentTab, setCurrentTab] = useState('info');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  // const [qrCodeImage, setQrCodeImage] = useState('');

  const handleCloseDeleteModel = () => setShowDeleteModal(false);

  // const handleCloseQrCodeModal = () => setShowQrCodeModal(false);

  const chargerDetailData = useSelector((state) => state.charger.chargerDetails);
  // const isLoader = useSelector((state) => state.charger.isLoading);

  const getChargerDetail = useCallback((id) => {
    dispatch({ type: GET_CHARGER, payload: id });
  }, []);

  // const getQrCode = useCallback(() => {
  //   dispatch({
  //     type: GENERATE_QR_CODE,
  //     payload: chargerId,
  //     cb: (res) => {
  //       if (res) {
  //         if (_.get(res, 'status') === 200) {
  //           setQrCodeImage(res.data.image);
  //         }
  //       }
  //     },
  //   });
  // }, []);

  const editChargerDetail = useCallback(() => {
    const chargerData = {
      chargerId: chargerDetailData.id,
      is_enabled: chargerDetailData.is_enabled !== true,
    };
    dispatch({ type: CHANGE_CHARGER_STATUS, payload: chargerData });
  }, [chargerDetailData]);

  useEffect(() => {
    getChargerDetail(chargerId);
  }, [chargerId]);

  useEffect(() => {
    if (copied) {
      toast.success('Copied!');
    }
  }, [copied]);

  const DeleteCharger = useCallback((id) => dispatch({ type: DELETE_CHARGER, payload: id }), []);

  const setTab = (e, tab) => {
    e.preventDefault();
    setCurrentTab(tab);
  };

  const handleClickCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const currentStatus = _.has(chargerDetailData, 'is_enabled') ? (chargerDetailData.is_enabled ? 'Enabled' : 'Disabled') : 'Enable';
  const chargerStatus = _.get(chargerDetailData, 'charger_status', '');
  let statusClassName = chargerStatus === 'Unavailable' ? 'unavailable--text' : chargerStatus === 'Available' ? 'available--text' : 'disabled--text',
    btnLabel,
    btnClassName;
  if (currentStatus === 'In Use') {
    statusClassName = 'in-use--text';
    btnLabel = 'Stop';
    btnClassName = 'btn--red';
  } else if (currentStatus === 'Disabled') {
    // statusClassName = 'disabled--text';
    btnLabel = 'Re-enable';
    btnClassName = 'btn--blue';
  }

  const handleClick = () => {
    if (btnLabel === 'Re-enable') {
      editChargerDetail();
    }
  };

  // const downloadImage = () => {
  //   //download qr code image
  //   saveAs(qrCodeImage, 'QR Code.jpg');
  // };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader
          title={_.get(chargerDetailData, 'charger_id', '')}
          backTo={location.state ? `/${location.state?.backTo}` : `/stations/${_.get(chargerDetailData, 'charging_station.id')}`}
        />
        <div className="page-content-wrapper scrollable">
          {/* {isLoader ? (
            <div className="charger-detail-page__main">
              <div className="loader--block">
                <Spinner as="span" animation="border" size="lg" role="status" />
                <span className="ps-2">Loading...</span>
              </div>
            </div>
          ) : ( */}
          <div className="charger-detail-page__main">
            <Card>
              <div className="charger-profileInfo">
                <div className="charger-detail__one">
                  <div className="charger-box-main">
                    <div className="charger-box">
                      <div className="charger-icon">
                        <span>
                          <BsFillPlugFill className="plug--icon" />
                        </span>
                      </div>

                      <div className="charger-profileInfo-wrapper">
                        <div className="charger--name">{_.get(chargerDetailData, 'charger_id', '')}</div>

                        <div className="charger-status-action__block charger-status--info">
                          <div className="charger-status--block">
                            <div
                              className={`charger--status ${statusClassName}`}
                              style={{
                                borderRadius: '20px',
                                padding: '5px 20px',
                                fontSize: '0.8rem',
                                fontWeight: '600',
                                background:
                                  chargerDetailData.charger_status === 'Unavailable'
                                    ? '#ff8b00'
                                    : chargerDetailData.charger_status === 'In Use'
                                    ? 'royalblue'
                                    : chargerDetailData.charger_status === 'Coming Soon'
                                    ? 'gray'
                                    : chargerDetailData.charger_status === 'PowerLoss'
                                    ? '#BD210B'
                                    : 'forestGreen',
                                color: 'white',
                              }}
                            >
                              {currentStatus === 'Disabled' && (
                                <span className={currentStatus === 'Disabled' ? 'disabled--text text-size' : 'available--text text-size'}>
                                  <BsDot className="dot--icon" size={20} />
                                  {'Disabled'}
                                </span>
                              )}
                              <span>
                                {/* <BsDot className="dot--icon" /> */}
                                {chargerStatus}
                              </span>
                            </div>
                          </div>

                          <div className="charger-action--block">
                            {btnLabel && (
                              <Button className={`charger-action--btn ${btnClassName}`} onClick={handleClick}>
                                {btnLabel}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="charger-detail__two">
                  <div className="charger-meta-info">
                    <div className="copy-clipboard__block" title="Copy WebSocket URL">
                      <CopyToClipboard onCopy={handleClickCopy} text={_.get(chargerDetailData, 'ocpp_url', '')}>
                        <button className="copy-btn">
                          <FiCopy className="copy--icon" />
                        </button>
                      </CopyToClipboard>
                    </div>
                    {/* <div
                      title="Open Charger QR Code"
                      className="qr-code--btn"
                      onClick={() => {
                        getQrCode();
                        setShowQrCodeModal(true);
                      }}
                    >
                      <MdQrCodeScanner size={22} />
                    </div> */}
                  </div>
                  <div className="charger-dropdown_btn">
                    <DropDown
                      style={{ background: '$primary-bg-color', borderRadius: 'none' }}
                      popupClass="bottom-left"
                      trigger={
                        <div className="charger-dropdown-icon">
                          <FiMoreVertical size={30} />
                        </div>
                      }
                    >
                      <ul>
                        <li className="item">
                          <div className="charger--switch" onClick={editChargerDetail}>
                            <Switch
                              label={'Disable Charger'}
                              // onChange={() => setChecked(!checked)}
                              checked={chargerDetailData.is_enabled === false}
                              // onClick={editChargerDetail}
                            />
                          </div>
                        </li>
                        <li className="item">
                          <div className="delete--charger" onClick={() => setShowDeleteModal(true)}>
                            <span>
                              <RiDeleteBin6Line color={'#E12F17'} />
                            </span>
                            <span className="delete-charger-text"> {'Delete Charger'} </span>
                          </div>
                        </li>
                        <li className="item" onClick={() => history.push(`/editCharger/${chargerDetailData.id}`)}>
                          <div className="edit--charger">
                            <span>
                              <FiEdit2 title="Edit" color="skyblue" />
                            </span>
                            <span className="edit-charger-text">{'Edit Charger'}</span>
                          </div>
                        </li>
                      </ul>
                    </DropDown>
                  </div>
                </div>
              </div>
              <div className="charger-detail__block-main">
                <div className="tab-block">
                  <div className="tab-nav-block">
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'info' ? 'active' : ''}`}
                      onClick={(e) => setTab(e, 'info')}
                      data-content="tab-info"
                    >
                      Information
                    </a>
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'chargerLogs' ? 'active' : ''}`}
                      onClick={(e) => setTab(e, 'chargerLogs')}
                      data-content="tab-server-logs"
                    >
                      Charger Logs
                    </a>
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'serverLogs' ? 'active' : ''}`}
                      onClick={(e) => setTab(e, 'serverLogs')}
                      data-content="tab-server-logs"
                    >
                      Server Logs
                    </a>
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'sequentialLogs' ? 'active' : ''}`}
                      onClick={(e) => setTab(e, 'sequentialLogs')}
                      data-content="tab-server-logs"
                    >
                      Sequential Logs
                    </a>
                    <a
                      href="#"
                      className={`tab-nav__item ${currentTab === 'serverOperations' ? 'active' : ''}`}
                      onClick={(e) => setTab(e, 'serverOperations')}
                      data-content="tab-server-operations"
                    >
                      Server Operations
                    </a>
                  </div>
                  <div className="tab-content-block">
                    <div className="tab-content__item active" id="charger-details-tab">
                      {currentTab === 'info' && <ChargerInfo />}
                      {currentTab === 'chargerLogs' && <LogInfo logFrom="charger" />}
                      {currentTab === 'serverLogs' && <LogInfo logFrom="server" />}
                      {currentTab === 'sequentialLogs' && <LogInfo activeTab="sequentialLogs" searchBoxWidth={500} />}
                      {currentTab === 'serverOperations' && <ServerOperationInfo />}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          {/* )} */}
          <Footer />
        </div>
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="charger" onClose={handleCloseDeleteModel} onRemove={() => DeleteCharger(chargerDetailData.id)} />
          </Modal>
        )}
        {/* {showQrCodeModal && (
          <Modal show={showQrCodeModal} centered onHide={handleCloseQrCodeModal}>
            <React.Fragment>
              <div className="qr-code-modal--main">
                <Card>
                  <div className="qr-code__block">
                    <div className="qr-code-name">
                      <span> QR Code </span>
                    </div>
                    <div className="qr-code-close-btn">
                      <MdClose size={30} color="#be210b" onClick={handleCloseQrCodeModal} />
                    </div>
                  </div>
                  <div className="qrCode--img">
                    {qrCodeImage ? <img src={qrCodeImage} alt="" /> : <div className={'empty-qrCode'}>No Qr Code Available</div>}
                  </div>
                  <div className="qrCode--button">{qrCodeImage && <Button onClick={downloadImage}>Download QRCode</Button>}</div>
                </Card>
              </div>
            </React.Fragment>
          </Modal>
        )} */}
      </LayoutContainer>
    </React.Fragment>
  );
};
export default ChargerDetail;
