import React from 'react';
import TenantAdminList from 'view/tenantAdmin/TenantAdminList';
import Dashboard from 'view/dashboard';
import { useSelector } from 'react-redux';
import ChargerStatus from 'view/chargerStatus';

const HomePage = () => {
  const role = useSelector((state) => state.profile.userProfile.role);
  return <React.Fragment>{role === 'super_admin' ? <TenantAdminList /> : role === 'support' ? <ChargerStatus /> : <Dashboard />}</React.Fragment>;
};

export default HomePage;
